import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/components/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./modules/components/products/products.module').then(
        (m) => m.ProductsModule
      ),
  },
  {
    path: 'products/skulu',
    loadChildren: () =>
      import('src/app/modules/components/skulu/skulu.module').then(
        (m) => m.SkuluModule
      ),
  },
  {
    path: 'products/device',
    loadChildren: () =>
      import('src/app/modules/components/device/device.module').then(
        (m) => m.DeviceModule
      ),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./modules/components/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./modules/components/contact/contact.module').then(
        (m) => m.ContactModule
      ),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./modules/components/about/about.module').then(
        (m) => m.AboutModule
      ),
  },
  {
    path: 's/:id',
    loadChildren: () =>
      import('./modules/components/shorten-url/shorten-url.module').then(
        (m) => m.ShortenUrlModule
      ),
  },
  {
    path: 'apply',
    loadChildren: () =>
      import('./modules/components/apply/apply.module').then(
        (m) => m.ApplyModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/components/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
